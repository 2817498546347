import { C9Logger } from 'lib-js-log-client';
import * as Cookies from 'es-cookie';
import { AjaxInterceptor, AjaxInterceptorImpl } from './ajax-interceptor';
import { BaseClient, BaseClientImpl } from './base.client';
import { KeepSessionAlive, KeepSessionAliveImpl } from './keep-session-alive';
import { SASCI360Tracking } from './sas-ci360-tracking';

const JWT_COOKIE_NAME = 'jwt';
const SSLSESSION_COOKIE_NAME = 'sslsessionid_temp';

/**
 * Alias for the union type for string, null, or undefined
 * @ignore
 */
type StringOrEmpty = string | null | undefined;

/**
 * The Auth client provides utilities for post login actions as well as
 * maintaining and performing activities related to an existing login session.
 *
 * @category Auth client
 */
export interface Auth extends BaseClient {
    readonly secure: boolean;
    readonly cookieDomain: string;
    readonly interceptor?: AjaxInterceptor;
    readonly keepSessionAlive?: KeepSessionAlive;

    readonly ping: () => Promise<void>;
    readonly getAuthToken: () => string | undefined;

    /**
     * The legacy callback for the NemID login successful flow.
     *
     * @deprecated In subsequent versions, the NemID login flow will be replaced by the newer Nets-eID flow.
     */
    readonly onLoginSucceeded: (jwt: string, sslsession: string, defaultRedirect?: string) => Promise<void>;

    /**
     * The callback function to handle successful login flows that occurred via the
     * Nets-eID login flow.
     *
     * @param redirectUrl The redirect url from the Nets-eID login client; optional.
     */
    readonly onLoginSucceededV2: (redirectUrl?: string) => Promise<void>;

    /**
     * Redirects the user's browser to the post login path.
     */
    readonly doRedirect: (redirectUrl: string) => void;
    readonly refreshAuthToken: (jwt: string) => void;
    readonly resolveAuthCookieDomain: (hostname: string) => string;
    readonly domainRequiresSecure: (hostname: string) => boolean;
}

/**
 * @internal
 */
export class AuthImpl extends BaseClientImpl implements Auth {
    /**
     * The default application name that identifies this client which will be included in logging statements
     */
    public static APP_NAME = 'AuthClient';

    private static REGEX_LOCAL_HOST = /^.*localhost$/;

    private _keepSessionAlive: KeepSessionAlive | undefined;
    private _ci360: SASCI360Tracking;

    private readonly _redirect: StringOrEmpty;
    private readonly apiAwsBase: string;

    private readonly _cookieDomain: string;
    private readonly _secure: boolean;
    private readonly _interceptor: AjaxInterceptor;

    constructor(
        ci360: SASCI360Tracking,
        logger: C9Logger,
        redirect: StringOrEmpty,
        keepSessionAliveUrl: StringOrEmpty, //TODO Remove
        hostname = 'localhost',
        apiAwsBase = 'localhost'
    ) {
        super(logger);

        this._ci360 = ci360;

        this._cookieDomain = this.resolveAuthCookieDomain(hostname);
        this._secure = this.domainRequiresSecure(hostname);

        this._redirect = redirect;
        this.apiAwsBase = apiAwsBase;

        this._interceptor = AjaxInterceptorImpl.getInstance(this.getAuthToken.bind(this));
        this._interceptor.startup();

        // If there's no JWT, then do nothing and try again after the elapsed timeout (5 mins)
        if (this.getAuthToken() && keepSessionAliveUrl) {
            this.logger.scaffold({ force: true }).debug(`Starting "keep session alive" client`, AuthImpl.APP_NAME);
            this._keepSessionAlive = new KeepSessionAliveImpl(
                logger,
                this._cookieDomain,
                this._secure,
                this.getAuthToken.bind(this),
                keepSessionAliveUrl
            );
        }
    }

    public get cookieDomain(): string {
        return this._cookieDomain;
    }

    public get secure(): boolean {
        return this._secure;
    }

    public get interceptor(): AjaxInterceptor {
        return this._interceptor;
    }

    public get keepSessionAlive(): KeepSessionAlive | undefined {
        return this._keepSessionAlive;
    }

    public getAuthToken(): string | undefined {
        return Cookies.get(JWT_COOKIE_NAME);
    }

    public async ping(): Promise<void> {
        if (this.keepSessionAlive) {
            await this.keepSessionAlive.ping();
        }
    }

    public async onLoginSucceeded(jwt: string, sslsession: string, defaultRedirect?: string): Promise<void> {
        this.refreshAuthToken(jwt);
        const fiveMinFromNow = new Date();
        fiveMinFromNow.setMinutes(fiveMinFromNow.getMinutes() + 5);

        if (sslsession) {
            this.setTempAuthCookie(SSLSESSION_COOKIE_NAME, sslsession, fiveMinFromNow);
        }

        try {
            const response = await this._ci360.getUserId(jwt);

            if (response) {
                this._ci360.initialize({ id: response.id });
            }
        } catch (error) {
            this.createLogEvent('error', `[CI360] getUserId Error: ${(error as Error).message}`, AuthImpl.APP_NAME);
        }

        const hasRedirect =
            typeof this._redirect !== 'undefined' && this._redirect !== null && this._redirect.length > 0;
        const hasDefaultRedirect =
            typeof defaultRedirect !== 'undefined' && defaultRedirect !== null && defaultRedirect.length > 0;

        const finalRedirect = hasRedirect ? (this._redirect as string) : hasDefaultRedirect ? defaultRedirect : '';

        const finalizeLogonRedirect = '/afslutlogon?ref=' + encodeURIComponent(finalRedirect);

        this.doRedirect(finalizeLogonRedirect);
    }

    public async onLoginSucceededV2(redirectUrl?: string): Promise<void> {
        try {
            const jwt = this.getAuthToken();
            if (typeof jwt === 'undefined') {
                throw new Error('user jwt cookie is missing');
            }

            const response = await this._ci360.getUserId(jwt);

            if (response) {
                this._ci360.initialize({ id: response.id });
            }
        } catch (e) {
            this.createLogEvent('error', `[CI360] getUserId Error: ${(e as Error).message}`, AuthImpl.APP_NAME);
        } finally {
            if (redirectUrl) {
                window.setTimeout(() => {
                    this.doRedirect(redirectUrl);
                }, 100);
            }
        }
    }

    public doRedirect(redirectUrl: string): void {
        window.location.replace(redirectUrl);
    }

    public refreshAuthToken(jwt: string): void {
        if (jwt) {
            this.setAuthCookie(JWT_COOKIE_NAME, jwt);
        }
    }

    public resolveAuthCookieDomain(hostname: string): string {
        if (!hostname) {
            return '';
        }

        if (hostname.match(AuthImpl.REGEX_LOCAL_HOST)) {
            return hostname;
        }

        const domainParts = hostname.split('.');
        if (domainParts.length === 1) {
            return hostname;
        }

        return '.' + domainParts.splice(-2).join('.'); // taken .nordeapension.dk domain
    }

    public domainRequiresSecure(hostname: string): boolean {
        return !hostname.match(AuthImpl.REGEX_LOCAL_HOST);
    }

    /**
     *
     * @private
     *
     * @param name
     * @param value
     * @param expires
     */
    private setTempAuthCookie(name: string, value: string, expires: Date): void {
        Cookies.set(name, value, {
            domain: this.cookieDomain,
            secure: this.secure,
            expires: expires
        });
    }

    /**
     *
     * @private
     *
     * @param name
     * @param value
     */
    private setAuthCookie(name: string, value: string): void {
        Cookies.set(name, value, {
            domain: this.cookieDomain || 'localhost',
            secure: this.secure || false
        });
    }
}
