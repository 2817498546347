import { C9Logger, LogLevels } from 'lib-js-log-client';
/**
 * The base client can be inherited by other clients in this module
 * to leverage common functionality such as making log statements
 * in a consistent format.
 *
 * This documentation does not describe the protected methods contained in this class. For further information, dive into the code!
 */
export interface BaseClient {
    /**
     * The lib-js-log-client instance that is exposed via a setter and getter method.
     */
    readonly logger: C9Logger;
}

/**
 * @internal
 *
 * @extends {BaseClient}
 */
export class BaseClientImpl implements BaseClient {
    private _logger: C9Logger;

    constructor(logger: C9Logger) {
        this._logger = logger;
    }

    get logger() {
        return this._logger;
    }

    set logger(logger: C9Logger) {
        this._logger = logger;
    }

    /**
     * Convenience method for logging messages using the lib-js-log-client.
     *
     * @param level The log level to be used
     * @param message The message to log
     * @param category The client name within top-context that the log is to be associated with
     */
    protected createLogEvent(
        level: Extract<keyof C9Logger, 'info' | 'warn' | 'debug' | 'error'>,
        message: string,
        category?: string
    ): void {
        this._logger[level](
            typeof message === 'object' || Array.isArray(message) ? JSON.stringify(message) : message,
            category
        ).finally(() => {});
    }

    /**
     * Convenience method for logging messages in object or array format using the lib-js-log-client.
     *
     * @param level The log level to be used
     * @param key The key for the object/array being logged
     * @param message The object/array to log
     * @param category The client name within top-context that the log is to be associated with
     */
    protected createEntitiesLogEvent(
        level: Extract<keyof C9Logger, 'info' | 'warn' | 'debug' | 'error'>,
        key: string,
        message: Record<string, unknown> | Array<unknown>,
        category?: string
    ): void {
        this._logger.entities(LogLevels[level], key, message, category).finally(() => {});
    }
}
