import { C9Logger } from 'lib-js-log-client';
import { BaseClientImpl } from '../base.client';
import { Subscription, EventBus } from '../event-bus';

/**
 * The various statuses that the login window may send to the main window
 * based on the login flow outcome.
 *
 * These statuses are outside the control of this client. They are defined
 * and maintained in the `c9-neb-login-api` lambda. This `enum` only
 * maintains a "copy" of the possible message statuses in this codebase
 * for developement convenience.
 *
 * @internal
 */
export enum MessageStatus {
    /**
     * The login flow was successful and the user is now logged in
     */
    LOGGEDIN = 'LOGGEDIN',
    /**
     * The user aborted the login flow.
     */
    ABORTED = 'ABORTED'
}

/**
 * The message data payload that will be received in message events.
 *
 * @internal
 */
export interface LoginNebAuthorizeMessage {
    /**
     * The message subject
     */
    readonly subject: string;
    /**
     * The message status
     */
    readonly status: MessageStatus;
    /**
     * The valid target url that the client should redirect to.
     */
    readonly targetUrl?: string;
}

/**
 * The LoginPopup class is an internal class that renders and performs
 * state management for the window popup that serves the login flow content
 * from the c9-neb-login-api lambda.
 *
 * @internal
 */
export class LoginPopup extends BaseClientImpl {
    public static EVENTS = {
        CLOSE: '__C9_LOGIN_POPUP__CLOSE',
        OPEN: '__C9_LOGIN_POPUP__OPEN',
        FOCUSED: '__C9_LOGIN_POPUP__FOCUSED',
        LOGIN_SUCCEEDED: '__C9_LOGIN_POPUP__SUCCEEDED',
        LOGIN_ERROR: '__C9_LOGIN_POPUP__LOGIN_ERROR',
        LOGIN_ABORTED: '__C9_LOGIN_POPUP__LOGIN_ABORTED'
    };

    public static POPUP_WINDOW_TITLE = 'Log ind';
    public static MESSAGE_SUBJECT = 'APP_WEB_LOGIN_NEB';

    private _url: string;
    private _popup: Window | null | undefined;
    private _appName: string;
    private _eventBus: EventBus;

    private _checkIfClosedIntervalHandler: number | undefined;
    private _checkIfOpenedTimeoutHandler: number | undefined;
    private _ensurePopupFocusTimeoutHandler: number | undefined;
    private _modalOverlayHideSubscription: Subscription | undefined;

    private _boundMessageHandler = this.messageHandler.bind(this);
    private _boundEnsurePopupFocus = this.ensurePopupFocus.bind(this);

    /**
     * Creates and maintains the window popup that will serve the login flow content.
     *
     * @param url The url to be opened in the popup window
     * @param logger An instance of the lib-js-log-client
     * @param appName The app name for logging purposes
     * @param eventBus The hybrid app event bus
     */
    constructor(url: string, logger: C9Logger, appName: string, eventBus: EventBus) {
        super(logger);

        this._url = url;
        this._appName = appName;
        this._eventBus = eventBus;
    }

    /**
     * Shows the popup window with content from the supplied url and the window settings specified.
     * If the popup already exists in memory, the refocus it.
     */
    public show(windowName?: string): void {
        // If the popup doesn't exist or is closed, then only do we create a new one...
        if (!this._popup || this._popup?.closed) {
            this._popup = window.open(
                this._url,
                windowName ?? LoginPopup.POPUP_WINDOW_TITLE,
                windowName ? undefined : this.resolveWindowFeatures()
            );
            this.checkIfOpened();
            this.checkIfClosed();
            this.addListeners();
        }
        this.focus();
    }

    /**
     * Focuses the window popup
     */
    public focus(): void {
        if (this._popup) {
            this._popup.focus();
            this._eventBus.publish(LoginPopup.EVENTS.FOCUSED);
        }
    }

    /**
     * Close the window popup
     */
    public close(): void {
        this.cleanup();
        this._eventBus.publish(LoginPopup.EVENTS.CLOSE);
    }

    /**
     * Remove any references or listeners to the current popup.
     *
     * @outcome An optional value that specifies the outcome of an operation that is triggering the cleanup
     */
    public cleanup(outcome?: string): void {
        if (outcome) {
            // Only log LOGIN_END if we have an outcome
            this.createLogEvent('info', `[LOGIN END] ${outcome}`, this._appName);
        }

        try {
            if (this._popup) {
                this._popup.close();
                this._popup = undefined;
                this.createLogEvent('debug', 'Login popup was closed', this._appName);
            } else {
                // Only log the warning when there's an outcome
                // If cleanup was called with an outcome, it means that we are at the end of a login flow
                outcome && this.createLogEvent('warn', 'No login popup window was found', this._appName);
            }
        } catch (e) {
            // Only log error when there's an outcome
            // If cleanup was called with an outcome, it means that we are at the end of a login flow
            outcome && this.createLogEvent('error', 'An error was thrown when closing the popup window', this._appName);
        } finally {
            // publish that cleanup has happened and it is safe to redirect
            this.removeListeners();
            window.clearInterval(this._checkIfClosedIntervalHandler);
            window.clearTimeout(this._checkIfOpenedTimeoutHandler);
        }
    }

    /**
     * Add event listeners for events that this class should react to.
     *
     * @private
     */
    private addListeners(): void {
        window.addEventListener('message', this._boundMessageHandler, false);
        window.addEventListener('focus', this._boundEnsurePopupFocus, false);
    }

    /**
     * Remove event listeners set up in this class.
     *
     * @private
     */
    private removeListeners(): void {
        window.removeEventListener('message', this._boundMessageHandler, false);
        window.removeEventListener('focus', this._boundEnsurePopupFocus, false);
    }

    /**
     * Handler for messages emitted by the login popup.
     *
     * @private
     *
     * @param {LoginPopup} this The function scope
     * @param {MessageEvent<LoginNebAuthorizeMessage>} event The message event
     */
    private messageHandler(this: LoginPopup, event: MessageEvent<LoginNebAuthorizeMessage>): void {
        const subject = event.data?.subject;
        if (subject === LoginPopup.MESSAGE_SUBJECT) {
            this.createEntitiesLogEvent(
                `debug`,
                `LoginPopup.messageHandler was invoked with event data`,
                event.data as unknown as Record<string, unknown>,
                this._appName
            );

            const { status } = event.data;
            switch (status) {
                case MessageStatus.LOGGEDIN:
                    {
                        const { targetUrl } = event.data;
                        this._eventBus.publish(LoginPopup.EVENTS.LOGIN_SUCCEEDED, {
                            targetUrl: targetUrl ?? this._url
                        });
                    }
                    break;
                case MessageStatus.ABORTED:
                    {
                        this.createLogEvent('info', `[LOGIN END] ${LoginPopup.EVENTS.LOGIN_ABORTED}`, this._appName);
                        this._eventBus.publish(LoginPopup.EVENTS.LOGIN_ABORTED);
                    }
                    break;
                default:
                    {
                        this.createLogEvent('info', `[LOGIN END] ${LoginPopup.EVENTS.LOGIN_ABORTED}`, this._appName);

                        this.createLogEvent(
                            'warn',
                            `LoginPopup.messageHandler was invoked with expected subject ${subject} but unknown status ${status}`,
                            this._appName
                        );

                        // If we get an unknown message type, we treat it the same as an aborted login attempt
                        this._eventBus.publish(LoginPopup.EVENTS.LOGIN_ABORTED);
                    }
                    break;
            }
        }
    }

    /**
     * Creates a string describing all the relevant features of the popup window.
     *
     * @private
     *
     * @param width The width of the popup window
     * @param height  The height of the popup window
     * @param top The distance of the popup window from top of the page
     * @param left The left offset of the popup window from the page
     * @returns {string} A compatible string containing all the features
     */
    private resolveWindowFeatures(width = 452, height = 750, top?: number, left?: number): string {
        const features: Record<string, string | number> = {
            toolbar: 'no',
            location: 'no',
            directories: 'no',
            status: 'no',
            menubar: 'no',
            scrollbars: 'no',
            resizable: 'no',
            width,
            height,
            top: top ?? window.outerHeight / 2 + window.screenY - height / 2,
            left: left ?? window.outerWidth / 2 + window.screenX - width / 2
        };

        return Object.keys(features)
            .map((key) => {
                const value = features[key];
                if (typeof value !== 'undefined' && value !== null) {
                    return `${key}=${value}`;
                }
            })
            .join(', ');
    }

    /**
     * Ensures that the popup remains in focused in the browser if it is being shown.
     *
     * @private
     *
     * @param this
     */
    private ensurePopupFocus(this: LoginPopup): void {
        if (this._ensurePopupFocusTimeoutHandler) {
            window.clearTimeout(this._ensurePopupFocusTimeoutHandler);
        }
        this._ensurePopupFocusTimeoutHandler = window.setTimeout(this.focus.bind(this), 200);
    }

    /**
     * Checks if the popup window was closed.
     *
     * @private
     */
    private checkIfClosed(): void {
        this._checkIfClosedIntervalHandler = window.setInterval(() => {
            if (this._popup?.closed === true) {
                this.createLogEvent('debug', 'popup detected to be closed by checkIfClosed interval', this._appName);
                this.close();
            }
        }, 1000);
    }

    /**
     * Run a check after 1 second to see if the popup actually opened.
     *
     * @private
     */
    private checkIfOpened(): void {
        this._checkIfOpenedTimeoutHandler = window.setTimeout(() => {
            if (!this._popup || this._popup?.closed || typeof this._popup?.closed === 'undefined') {
                this.createLogEvent('warn', 'the client browser blocked the popup window', this._appName);
                window.clearInterval(this._checkIfClosedIntervalHandler);
                this.removeListeners();
            }
            window.clearTimeout(this._checkIfOpenedTimeoutHandler);
        }, 1000);
    }
}
