import { C9Logger } from 'lib-js-log-client';
import { BaseClient, BaseClientImpl } from './base.client';
import { EventBus } from './event-bus';

/**
 * An array of stakeholder views that is returned when this top context stakeholder client
 * invokes the c9-stakeholder service successfully.
 *
 * This is the return type of the Promise returned by {@linkcode StakeholderClientImpl.getViews}
 *
 * @category Stakeholder Client
 */
export type StakeholderViews = {
    /**
     * An array of stakeholder views returned from the stakeholder service
     */
    readonly views: Array<StakeholderView>;
};

/**
 * An object representing a stakeholder view object returned from the c9-stakeholder-service
 *
 * @category Stakeholder Client
 */
export type StakeholderView = {
    readonly activeUser: boolean;

    /**
     * Optional value that may only be present for a private type view
     */
    readonly cpr?: string;

    /**
     * Optional value that may only be present for a business type view
     */
    readonly cvr?: string;

    /**
     * The filters applied
     */
    readonly filter: string;

    /**
     * The id of the stakeholder view
     */
    readonly id: string;

    /**
     * The stakeholder view name
     */
    readonly name: string;

    /**
     * If the filter value is "BROKER", this value may be present
     */
    readonly fullName?: string;

    readonly source: string;

    /**
     * The type of stakeholder view
     */
    readonly type: 'business' | 'private' | 'household';

    /**
     * Is the current stakeholder view selected in the current session
     */
    readonly selected?: boolean;

    /**
     * This optional property is usually the length of the primaryCustomerIds array
     */
    readonly totalHouseholdMember?: number;
};

/**
 * The StakeholderClient offers pre-built functionality to interact with the C9 Stakeholder service.
 *
 * The 2 methods exposed allow consumers to get a list of stakeholder views and also set a selected view for
 * the given active user.
 *
 * The client comes with browser based caching that caches the views after the initial "getViews" call
 * is invoked so that subsequent invocations to getViews will not hit the backend service repeatedly. This
 * cache can be refreshed by supplying the "reload" flag with value "true".
 *
 * The StakeholderClient comes with in built state management for the fetch requests it makes.
 *
 * Both the setSelectedView and getViews method apply a state management pattern where the method will return an ongoing
 * promise (if there is one) to the caller in the event that a previous caller made a request to this method. This way,
 * all callers will ultimately receive a resolved result from an original promise. This prevents multiple promises from
 * being created witin a given time period that may result in inconsistent behavior.
 *
 * @category Stakeholder Client
 *
 */
export interface StakeholderClient extends BaseClient {
    /**
     * Gets all stakeholder views for the given filter.
     *
     * NOTE: At any given period, only ONE active fetch request to get stakeholder views for a given filter is allowed.
     * If another request for the same filter to get stakeholder views is made, the promise of the previous request will
     * be returned. Do take note that if the same filter is requested with a force reload, then a new promise will be made
     * even though an ongoing one with the same filter is still in progress.
     *
     * @param filter The filter to apply
     * @param reload An optional flag to force reload the stakeholder view cache
     * @param appName An optional appName that when provided, will override the default APP_NAME configured in this client for this call
     *
     * @function
     */
    readonly getViews: (filter: string, reload?: boolean, appName?: string) => Promise<StakeholderViews>;

    /**
     *
     * Set the given stakeholder view identified by its view id as the current (selected) stakeholder view
     *
     * NOTE: At any given period, only ONE active fetch request to set the selected view for a given filter is allowed.
     * If another request for the same filter to get stakeholder views is made, the promise of the previous request will
     * be returned.
     *
     * @function
     *
     * @param viewId The stakeholder viewId to be selected
     * @param appName An optional appName that when provided, will override the default APP_NAME configured in this client for this call
     */
    readonly setSelectedView: (viewId: string, appName?: string) => Promise<SelectedViewResponse>;

    /**
     * The name of the event to publish to the {@linkcode EventBus}.
     * @param
     */
    readonly eventName: string;
}

/**
 * The response from calling `setSelectedView`.
 *
 * @category Stakeholder Client
 */
export type SelectedViewResponse = {
    /**
     * The selected view id
     */
    readonly selected: {
        /**
         * The id of the selected view
         */
        readonly id: string;
    };
};

/**
 * @internal
 */
export class StakeholderClientImpl extends BaseClientImpl implements StakeholderClient {
    /**
     * The name of the event to publish via the [[EventBus]]
     */
    private static readonly C9_STAKEHOLDER_VIEW_SELECTED = 'C9_STAKEHOLDER_VIEW_SELECTED';
    private static readonly APP_NAME = 'StakeholderClient';
    private static readonly V1 = 'v1';

    private readonly _eventBus: EventBus;
    private readonly _basePath: string;

    private _internalCache: Map<string, StakeholderViews> = new Map();
    private _getViewsPromiseCache: { [key: string]: Promise<StakeholderViews> } = {};
    private _setSelectedViewPromiseCache: { [key: string]: Promise<SelectedViewResponse> } = {};

    /**
     * Instantiates a new stakeholder client for top context
     * This constructor will be automatically invoked when Top Context is loaded.
     * The end user should not directly invoke this method.
     *
     * @private
     *
     * @param hybridAppEventBus
     * @param apiGatewayBasepath
     * @returns
     */
    constructor(hybridAppEventBus: EventBus, apiGatewayBasepath: string, logger: C9Logger) {
        super(logger);
        this._eventBus = hybridAppEventBus;
        this._basePath = apiGatewayBasepath;
    }

    public async setSelectedView(viewId: string, appName?: string): Promise<SelectedViewResponse> {
        if (!viewId) {
            this.createLogEvent('error', `StakeholderView id not defined`, appName ?? StakeholderClientImpl.APP_NAME);
            throw new Error('StakeholderView id not defined');
        }

        if (!this._internalCache || this._internalCache.size < 1) {
            this.createLogEvent(
                'error',
                `StakeholderView not loaded [internal cache not defined or empty]`,
                appName ?? StakeholderClientImpl.APP_NAME
            );

            throw new Error('StakeholderView not loaded');
        }

        // We return an ongoing promise
        if (typeof this._setSelectedViewPromiseCache[viewId] !== 'undefined') {
            return this._setSelectedViewPromiseCache[viewId];
        }

        // get and update selected stakeholder view
        let selectedStakeholderView: StakeholderView | null = null;
        const newCache: Map<string, StakeholderViews> = new Map();

        // Loop through each cached filter
        this._internalCache.forEach((stakeholderViews: StakeholderViews, key) => {
            // go through each stakeholder view in the filter
            const newViews = stakeholderViews.views.map((stakeholderView: StakeholderView) => {
                let newView: StakeholderView;
                if (stakeholderView.id === viewId) {
                    // and select any view that matches the requested view id
                    newView = { ...stakeholderView, selected: true };
                    selectedStakeholderView = newView;
                } else {
                    newView = { ...stakeholderView, selected: false };
                }
                return newView;
            });
            newCache.set(key, { views: newViews });
        });

        // If we cannot set the view in the front-end
        // then we won't call the backend to set the view
        // because something is out of sync
        if (selectedStakeholderView === null) {
            this.createLogEvent(
                'error',
                `view id "${viewId}" not found in cache`,
                appName ?? StakeholderClientImpl.APP_NAME
            );
            throw new Error(`StakeholderView id "${viewId}" not found in cache`);
        }

        // update cache
        this._internalCache = newCache;

        // publish the selected view before we update the backend
        this._eventBus.publish(StakeholderClientImpl.C9_STAKEHOLDER_VIEW_SELECTED, selectedStakeholderView);

        const promise = fetch(`${this._basePath}/stakeholder/${StakeholderClientImpl.V1}/views/${viewId}/selected`, {
            method: 'PUT',
            headers: {
                'x-top-appname': appName ?? StakeholderClientImpl.APP_NAME
            }
        })
            .then(async (response: Response) => {
                const responseJson: SelectedViewResponse = await response.json();

                if (!response.ok) {
                    this.createEntitiesLogEvent(
                        'error',
                        `error selecting view id "${viewId}"`,
                        responseJson,
                        appName ?? StakeholderClientImpl.APP_NAME
                    );

                    throw new Error('Network error response was not ok.');
                }

                return responseJson;
            })
            .catch(async (error) => {
                this.createLogEvent(
                    'error',
                    `error selecting view id "${viewId}": ${error.message} [fetch exception]`,
                    appName ?? StakeholderClientImpl.APP_NAME
                );
                throw error;
            })
            .finally(() => {
                delete this._setSelectedViewPromiseCache[viewId];
            });

        this._setSelectedViewPromiseCache[viewId] = promise;
        return promise;
    }

    public async getViews(filter = 'GROUPED', reload = false, appName?: string): Promise<StakeholderViews> {
        const usedFilter = filter ? filter : 'GROUPED';
        const query = '?filter=' + usedFilter;

        let url = `${this._basePath}/stakeholder/${StakeholderClientImpl.V1}/views${query}`;

        // use internalCache if found and not force reload
        if (!reload && this._internalCache && this._internalCache.size >= 1) {
            const response = this._internalCache.get(usedFilter);
            if (response) {
                return response;
            }
        }

        if (reload) {
            url += '&reload=true';
        }

        // Returns the promise for an already ongoing fetch request if a URL match is found in the state management
        if (typeof this._getViewsPromiseCache[url] !== 'undefined') {
            return this._getViewsPromiseCache[url];
        }

        const cache: RequestCache = 'no-store';
        const fetchArgs: RequestInit = {
            method: 'GET',
            cache,
            headers: {
                'x-top-appname': appName ?? StakeholderClientImpl.APP_NAME,
                'cache-control': 'no-store'
            }
        };

        const promise = fetch(url, fetchArgs)
            .then(async (res: Response) => {
                const responseJson = await res.json();

                if (res.ok) {
                    this._internalCache.set(usedFilter, responseJson);
                    return responseJson;
                }

                this.createEntitiesLogEvent(
                    'error',
                    `Error getting view for filter "${filter}"`,
                    {
                        requestUrl: `[${fetchArgs.method}] ${url} [${cache}]`,
                        payload: JSON.stringify(responseJson, undefined, 4)
                    },
                    appName ?? StakeholderClientImpl.APP_NAME
                );

                throw new Error(`Network error response was not ok. ${JSON.stringify(responseJson)}`);
            })
            .catch(async (e) => {
                this.createEntitiesLogEvent(
                    'error',
                    `Error getting view for filter "${filter}" [fetch exception]`,
                    {
                        payload: JSON.stringify(e.message, undefined, 4),
                        errorStack: JSON.stringify((e as Error).stack)
                    },
                    appName ?? StakeholderClientImpl.APP_NAME
                );
                throw e;
            })
            .finally(() => {
                delete this._getViewsPromiseCache[url];
            });

        this._getViewsPromiseCache[url] = promise;
        return promise;
    }

    public get eventName(): string {
        return StakeholderClientImpl.C9_STAKEHOLDER_VIEW_SELECTED;
    }
}
