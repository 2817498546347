import { EndpointConfig, SiteConfig } from './top-context';
import { Auth } from './auth';
import * as Cookie from 'es-cookie';

export interface Logout {
    execute(): void;
}

enum configEnv {
    dev = 'dev',
    integration = 'integration',
    preprod = 'pre-prod',
    prod = 'prod'
}

const envHostMapper: Record<configEnv, string> = {
    [configEnv.dev]: 'https://dnordeapensiondev2b2c.b2clogin.com/dnordeapensiondev2b2c.onmicrosoft.com',
    [configEnv.integration]: 'https://inordeapensionb2c.b2clogin.com/inordeapensionb2c.onmicrosoft.com',
    [configEnv.preprod]: 'https://ppnordeapensionb2c.b2clogin.com/ppnordeapensionb2c.onmicrosoft.com',
    [configEnv.prod]: 'https://nordeapensionb2c.b2clogin.com/nordeapensionb2c.onmicrosoft.com'
};

const logoutPath = '/B2C_1A_NLP_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=';

/**
 * @internal
 */
export class LogoutImpl implements Logout {
    private _logoutUrl: string | undefined = '';

    private readonly JWT_COOKIE_NAME = 'jwt';

    constructor(endpointConfig: EndpointConfig, siteConfig: SiteConfig, private auth: Auth) {
        if (typeof endpointConfig === 'undefined' || typeof siteConfig === 'undefined') {
            return;
        }

        const host = envHostMapper[siteConfig.env as configEnv] ?? envHostMapper[configEnv.dev];
        const postLogoutRedirectUrl = this.createLogoutRef(siteConfig.originalHost, siteConfig.sisterHost);
        this._logoutUrl = `${host}${logoutPath}${postLogoutRedirectUrl}`;
    }

    public execute(): void {
        Cookie.remove(this.JWT_COOKIE_NAME, {
            domain: this.auth.cookieDomain || 'localhost',
            secure: this.auth.secure || false
        });
        this._logoutUrl && this.auth.doRedirect(this._logoutUrl);
    }

    private createLogoutRef = (originalHost: string, sisterHost: string) => {
        if (originalHost.toLocaleLowerCase().includes('maegler')) {
            return `?ref=${encodeURIComponent(
                'https://' + sisterHost.replace(/min/, 'www') + '/erhverv/maegler-log-ind/'
            )}`;
        } else {
            return `${encodeURIComponent('https://' + sisterHost.replace(/min/, 'www'))}`;
        }
    };
}
